import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  font-size:22px;
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  padding-left:30px;
  padding-right:30px;
  font-weight: bold;
  color: var(--secondary-text);
  font-family: 'Blue Goblet Regular', sans-serif;
  // width: 100px;
  cursor: pointer;

  :active {
    opacity:0.3 !important; 
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 150px;
  @media (min-width: 767px) {
    width: 220px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.1);
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 100px;
  @media (min-width: 900px) {
    width: 150px;
  }
  @media (min-width: 1000px) {
    width: 200px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = async () => {
    console.log("hi");
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    // totalSupply() + 1 <= FREE_MINTS

    let supply = await blockchain.smartContract.methods.totalSupply().call();

    console.log(supply, "supply")

    if (supply + 1 <= 777) {
      blockchain.smartContract.methods.freeMint().estimateGas({from: blockchain.account})
      .then(gasAmount => {
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        blockchain.smartContract.methods
        .freeMint()
        .send({
          gasLimit: String(Math.floor(gasAmount * 1.05)),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account
        })  
        setClaimingNft(true);
      })
      .catch(err => {
        console.log(err);
        setFeedback(`An error occured while estimating gas! ${err}`);
        setClaimingNft(false);
      })
    } else {
      console.log("not free")
      blockchain.smartContract.methods.mint(mintAmount).estimateGas({from: blockchain.account, value: totalCostWei * mintAmount})
      .then(gasAmount => {
        console.log(totalGasLimit)
        console.log(data);
        console.log("Cost: ", totalCostWei * mintAmount);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        blockchain.smartContract.methods
        .mint(mintAmount)
        .send({
          gasLimit: String(Math.floor(gasAmount * 1.05)),
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei * mintAmount,
        })  
        setClaimingNft(true);
      })
      .catch(err => {
        console.log(err);
        setFeedback(`An error occured while estimating gas! ${err}`);
        setClaimingNft(false);
      })

    }

    };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    console.log(blockchain)

    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }

  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{padding: 24, backgroundColor: "var(--primary)" }}
      >

        <s.Container flex={1} jc={"center"} ai={"center"}>
          <StyledImg src={"https://lh3.googleusercontent.com/kfqmwoheW-rTEPRpyP5Oc8XIXBAtIphmyA_qnzabdU__VAMaJGZ3_GfxIB8ABkkX0fUdmmvqZHQPfXjKtZCVt_Q79CTRB0lLCWrKNg=s0"} />
        </s.Container>
        
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
  
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.10)",
              maxWidth: 500,
              height:600,
              margin:'auto'
            }}
          >
            <s.TextTitle
              style={{
                fontFamily: 'Blue Goblet Regular',
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextTitle>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  THE SALE HAS ENDED.
                </s.TextTitle>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  YOU CAN STILL FIND {CONFIG.NFT_NAME} ON
                </s.TextTitle>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE.toUpperCase()}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ maxWidth:"85%", textAlign: "center", color: "var(--accent-text)" }}
                >
                    THE FIRST 777 {CONFIG.NFT_NAME} ARE FREE (YOU CAN ONLY CLAIM 10 AT A TIME), EVERY MINT AFTER COSTS {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  EXCLUDING GAS FEES.
                </s.TextTitle>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container style={{marginTop:60}} ai={"center"} jc={"center"}>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      CONNECT TO THE {CONFIG.NETWORK.NAME.toUpperCase()} NETWORK
                    </s.TextTitle>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        console.log("hm,m")
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextTitle
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextTitle>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextTitle>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextTitle
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextTitle>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
          
          <s.SpacerLarge />
          
        </ResponsiveWrapper>

        <s.Container flex={1} jc={"center"} ai={"center"}>

          <StyledRoundButton
          
            style={{ backgroundColor:"#fff",color:"#eee",  width:40, height:40, lineHeight: 0.4 }}
            onClick={(e) => {
              window.open("https://twitter.com/ghoultown_wtf", '_blank')
            }}
            >
                <FontAwesomeIcon style={{fontSize:18, color:"rgba(245, 182, 10, 1)"}} icon={faTwitter} />

            </StyledRoundButton>
        </s.Container>
        
        <s.SpacerMedium />
          
{/* 
          <div style={{overflow:"hidden"}}>
            <div className="imageContainer">
              <img style={{position:"absolute", bottom:0}}src={"/config/images/background.png"}></img>
             </div>
          </div> */}

      </s.Container>


    </s.Screen>
  );
}

export default App;